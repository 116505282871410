<template>
  <IAmOverlay :loading="loading">
    <div style="min-width: 1080px;">
      <TrainTicketConfig
        :config="config"
        style="top: 48px; margin-top: 1rem"
      />

      <b-card
        no-body
        header-tag="header"
        border-variant="info"
        header-bg-variant="light-info"
        header-class="py-1"
        class="border mt-1"
      >
        <template #header>
          <h4 class="m-0">
            {{ $t('ticket.sectionsTitle.template') }}
          </h4>
        </template>

        <b-card-body
          class="p-1 bg-light"
          style="border-bottom-right-radius: inherit; border-bottom-left-radius: inherit;"
        >
          <ElectronicTicketTrain
            frontend-i18n
            is-config
            :config.sync="config"
            :listTrips="listTrips"
            :passengerTargetList="getListPassengerTarget"
            @resetNote="resetNote"
            @resetAgencyInfo="resetAgencyInfo"
          />
          <div class="d-flex justify-content-center">
            <b-button
              variant="primary"
              class="mt-1"
              @click="saveTicketConfig()"
            >
              {{ $t('save') }}
            </b-button>
          </div>
        </b-card-body>
      </b-card>
    </div>
  </IAmOverlay>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { BCard, BCardBody, BButton } from 'bootstrap-vue'

import { TRAIN_LIST_TRIPS_EXAMPLE } from '@/constants/ticket'
import service from '@/api/service'
import IAmOverlay from '@/components/IAmOverlay.vue'
import store from '@/store'

import storeModule from '@train/trainStoreModule'

import useToast from '@useToast'

import useGetTrainTicketConfig from './useGetTrainTicketConfig'

export default {
  name: 'ElectronicTicketTrainTemplateSettings',

  components: {
    BCard,
    BCardBody,
    BButton,

    IAmOverlay,
    TrainTicketConfig: () => import('./components/TrainTicketConfig.vue'),
    ElectronicTicketTrain: () => import('./ElectronicTicketTrain.vue'),
  },

  setup() {
    const {
      config,
      employeeId,
      getTicketConfig,
    } = useGetTrainTicketConfig()

    if (!store.hasModule('app-train')) {
      store.registerModule('app-train', storeModule)
    }

    const stateLoadingCategory = computed(() => store.state['app-train'].loadingCategory)
    const stateCategory = computed(() => store.state['app-train'].category)
    const getListPassengerTarget = computed(() => store.getters['app-train/getListPassengerTarget']) // (array) list

    // fetch category
    if ((!stateCategory.value) && !stateLoadingCategory.value) {
      store.dispatch('app-train/handleGetCategory')
    }

    // FIXME : LẤY CATEGORY RETURN PAX_TYPE_NAME

    const { toastError, toastSuccess } = useToast()

    const listTrips = ref(TRAIN_LIST_TRIPS_EXAMPLE)

    const loading = ref(false)

    async function saveTicketConfig() {
      loading.value = true
      try {
        await service.post(`/employees/${employeeId.value}/train-config`, config.value)
        getTicketConfig()
        toastSuccess('ticket.saveTicketConfigSuccess')
      } catch (error) {
        toastError('ticket.saveTicketConfigErr')
      } finally {
        loading.value = false
      }
    }

    function resetNote() {
      this.$bvModal
        .msgBoxConfirm('Xác nhận đặt lại ghi chú về mặc định?', {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          if (value) {
            if (config.value.locale === 'vi') {
              config.value.note = ''
            } else {
              config.value.noteEn = ''
            }
            saveTicketConfig()
          }
        })
    }

    function resetAgencyInfo() {
      this.$bvModal
        .msgBoxConfirm('Xác nhận đặt lại thông tin đại lý về mặc định?', {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          if (value) {
            if (config.value.locale === 'vi') {
              config.value.agencyInfo = ''
            } else {
              config.value.agencyInfoEn = ''
            }
            saveTicketConfig()
          }
        })
    }

    return {
      loading,
      config,
      listTrips,
      // paxs,
      saveTicketConfig,
      resetNote,
      resetAgencyInfo,
      getListPassengerTarget,
    }
  },
}
</script>
