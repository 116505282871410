var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('div', {
    staticStyle: {
      "min-width": "1080px"
    }
  }, [_c('TrainTicketConfig', {
    staticStyle: {
      "top": "48px",
      "margin-top": "1rem"
    },
    attrs: {
      "config": _vm.config
    }
  }), _c('b-card', {
    staticClass: "border mt-1",
    attrs: {
      "no-body": "",
      "header-tag": "header",
      "border-variant": "info",
      "header-bg-variant": "light-info",
      "header-class": "py-1"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "m-0"
        }, [_vm._v(" " + _vm._s(_vm.$t('ticket.sectionsTitle.template')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('b-card-body', {
    staticClass: "p-1 bg-light",
    staticStyle: {
      "border-bottom-right-radius": "inherit",
      "border-bottom-left-radius": "inherit"
    }
  }, [_c('ElectronicTicketTrain', {
    attrs: {
      "frontend-i18n": "",
      "is-config": "",
      "config": _vm.config,
      "listTrips": _vm.listTrips,
      "passengerTargetList": _vm.getListPassengerTarget
    },
    on: {
      "update:config": function updateConfig($event) {
        _vm.config = $event;
      },
      "resetNote": _vm.resetNote,
      "resetAgencyInfo": _vm.resetAgencyInfo
    }
  }), _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('b-button', {
    staticClass: "mt-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.saveTicketConfig();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('save')) + " ")])], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }