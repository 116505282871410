import { ref, computed, watch } from '@vue/composition-api'

import { DEFAULT_TRAIN_CONFIG, defaultTrainNoteVn, defaultTrainNoteEn } from '@/constants/ticket'
import service from '@/api/service'
import store from '@/store'

export default function useGetTrainTicketConfig(useWatch = true) {
  const config = ref(DEFAULT_TRAIN_CONFIG)
  const employeeId = computed(() => store.getters['userStore/getMeDataId'])
  const agencyInfo = computed(() => store.getters['userStore/getAgencyData'])
  let alreadyGetTicketConfig = false

  async function getTicketConfig() {
    if (!useWatch || alreadyGetTicketConfig) return
    try {
      if (!employeeId.value) return
      const res = await service.get(`/employees/${employeeId.value}/train-config`)
      res.data.color = res.data.color.toUpperCase()

      if (res?.data) {
        let bankAccount
        if (agencyInfo.value?.bankAccs ?? agencyInfo.value?.bankAccounts) {
          const defaultBankAccount = (agencyInfo.value?.bankAccs ?? agencyInfo.value?.bankAccounts)?.find(item => item.isDefault) || (agencyInfo.value?.bankAccs?.[0] ?? agencyInfo.value?.bankAccounts?.[0]) || null
          const isInclude = (agencyInfo.value?.bankAccs ?? agencyInfo.value?.bankAccounts).some(item => item.bankAccountNo === res.data.bankAccount?.bankAccountNo)
          bankAccount = isInclude ? res.data.bankAccount : defaultBankAccount
        }

        const agencyInfoVn = res.data.agencyInfo
         || `<div><b>${agencyInfo.value.agencyName}</b></div>
            <div>Địa chỉ: ${agencyInfo.value.agencyAddress}</div>
            <div>Hotline: ${agencyInfo.value.ownerPhoneNumber}</div>`
        const agencyInfoEn = res.data.agencyInfoEn
          || `<div><b>${agencyInfo.value.agencyName}</b></div>
            <div>Address: ${agencyInfo.value.agencyAddress}</div>
            <div>Hotline: ${agencyInfo.value.ownerPhoneNumber}</div>`
        const noteVn = res.data.note || defaultTrainNoteVn
        const noteEn = res.data.noteEn || defaultTrainNoteEn
        config.value = {
          ...config.value,
          ...res.data,
          bankAccount,
          locale: res.data.locale || 'vi',
          agencyInfo: agencyInfoVn,
          agencyInfoEn,
          note: noteVn,
          noteEn,
        }
        alreadyGetTicketConfig = true
      }
    } catch (error) {
      console.error({ error })
    }
  }

  if (useWatch) {
    watch(employeeId, val => {
      if (val) getTicketConfig()
    }, { immediate: true })
  }

  return {
    config,
    employeeId,
    getTicketConfig,
  }
}
